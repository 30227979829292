.App {
  text-align: center;
  overflow-x: hidden;
  background-color: #fdfdfd;
}

.logo-box {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 20px;
  background-color: red;
  cursor: pointer;
}
.App-logo {
  height: 6vmin;
  pointer-events: none;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.App-header-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #022345;
  opacity: 0.8;
  font-size: calc(15px + 2vmin);
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 1px 1px 10px 1px grey;
  box-shadow: 0px 1px #9e9e9e;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
}
.App-header {
  display: flex;
  min-height: 10vh;
  width: 90%;
  color: rgb(255, 255, 255);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
  gap: 10px;
}

.headertext {
  font-size: calc(4px + 2vmin);
  
}
.headertext:hover {
  color: #ffd60a;
  opacity: .8;
}

.App-header-logobox {
  display: flex;
  width: auto;
  height: 100%;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.App-header-child {
  display: flex;
  width: auto;
  height: 100%;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  margin-top: 10vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.startlayout {
  display: flex;
  height: 10vh;
  margin-top: 10vh;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: rgb(0, 0, 0);
  font-size: calc(2.5vmin);
  align-self: center;
  padding: 0px 5%;
}

.chatbutton {
  height: 6vh;
  width: 15vw;
  background-color: #ffd60a;
  border-radius: 4px;
  border-width: 0px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 400;
  color: #000;
  cursor: pointer;
}
.row {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.outline-box {
  height: auto;
  width: auto;
  background-color: white;
  border-width: thin;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}
.outline-recharge {
  height: auto;
  width: auto;
  background-color: white;
  border-width: thin;
  border-radius: 4px;
  border-color: green;
  color: green;
  padding: 5px 10px;
  cursor: pointer;
}
.outline-recharge:hover {
  background-color: green;
  color: white;
}

.cardlayout {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.card {
  display: flex;
  height: 20vh;
  width: 23vw;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 1px 1px 5px 1px rgb(201, 193, 193);
  border-radius: 10px;
  font-size: calc(2vmin);
  cursor: pointer;
}

.cardchild {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.textArea {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.cardimage {
  height: 12vh;
  width: 12vh;
  border-radius: 100%;
}

.iconbox {
  display: flex;
  flex-direction: row;
}
.chatColumn {
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
}
.chat {
  height: 4vh;
  width: 5vw;
  padding: 5px 10px;
  background-color: #ffffff;
  color: black;
  border-radius: 6px;
  border-color: #ffd60a;
}
.cardTextName {
  font-size: calc(2px + 2vmin);
  font-weight: 600;
}

.justify {
  text-align: justify;
  margin: 20px 5%;
  font-size: calc(20px);
  color: #000;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-image: linear-gradient( #e4e2e2, #ffffff);
  padding: 10px 10px;
  border-radius: 20px;
}

.labelText {
  font-size: calc(10px + 2.5vmin);
  font-weight: 600;
}

.labelText2 {
  font-size: calc(5px + 2vmin);
  font-weight: 600;
  text-decoration: underline;
}

.descriptionBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient( #f1f1f1, #ffffff);
  gap: 10px;
}

.textLeft {
  display: flex;
  align-items: flex-start;
}

.footer{
  height: 50vh;
  width: 100%;
  background-color: #022346;
  justify-content: center;
  align-items: center;
}

.footerClass {
  display: flex;
  height: 100%;
  width: 90%;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  color: white;
  margin: 0px 5%;
  flex-direction: column;
  text-align: left;
  gap: 20px;
}
.footerText {
  color: white;
}
.footerText:hover {
  margin-left: 10px;
}

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}
a:active {
  text-decoration: none;
  color: white;
}
